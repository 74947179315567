<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-zs__healthtickets}">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <el-input v-model="dataForm.id" placeholder="id" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.personCertificateNo"
            placeholder="证件号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.personMobile"
            placeholder="手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.personName"
            placeholder="姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.rightName"
            placeholder="权益名称"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input
            v-model="dataForm.rightName"
            placeholder="供应商"
            clearable
          ></el-input>
        </el-form-item> -->

        <el-form-item>
          <el-select
            v-model="dataForm.supplierAppCode"
            placeholder="供应商"
            label="appDesc"
            :clearable="true"
            style="width: 100%"
          >
            <el-option
              v-for="item in listByScope"
              :key="item.appCode"
              :label="item.appDesc"
              :value="item.appCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.status" placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getServiceOrderList()">{{
            $t('query')
          }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="id"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userRightId"
          label="用户权益id"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rightName"
          label="权益名称"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="supplierAppCode"
          label="供应商"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="personName"
          label="姓名"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="personMobile"
          label="手机号"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="personCertificateNo"
          label="证件号"
          header-align="center"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="entityId" label="业务实体id" header-align="center" align="center"></el-table-column> -->
        <!-- <el-table-column prop="ticketsName" label="工单名称" header-align="center" align="center"></el-table-column> -->
        <el-table-column
          prop="ticketsCode"
          label="服务单单号"
          header-align="center"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="ticketsDesc" label="工单详情" header-align="center" align="center"></el-table-column> -->
        <el-table-column
          prop="status"
          label="服务单状态"
          header-align="center"
          align="center"
          :filters="statusArray"
          :filter-method="filterStatus"
          :filter-multiple="false"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1">
              {{ $getDictLabel('service_order_status', scope.row.status) }}
            </el-tag>
            <el-tag v-if="scope.row.status === 2" type="info">
              {{ $getDictLabel('service_order_status', scope.row.status) }}
            </el-tag>
            <el-tag v-if="scope.row.status === 0" type="danger">
              {{ $getDictLabel('service_order_status', scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="creatorName"
          label="创建者"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="updaterName"
          label="更新者"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="updateDate"
          label="更新时间"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="GohealthticketsDetails(scope.row)"
              >处理</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click="GohealthticketsDetails(scope.row)"
              >{{ $t('update') }}</el-button
            > -->
            <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <!-- <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update> -->
      <!-- <healthticketsDetails ref="healthticketsDetails" @getMethod="getMethod"></healthticketsDetails>
      <advanceDetails ref="advanceDetails" @getMethod="getMethod"></advanceDetails>
      <seriousillnessDetail ref="seriousillnessDetail" @getMethod="getMethod"></seriousillnessDetail>
      <controlAndConsultationDetail ref="controlAndConsultationDetail" @getMethod="getMethod"></controlAndConsultationDetail>
      <OverseasClassDetail ref="OverseasClassDetail" @getMethod="getMethod"></OverseasClassDetail>
      <assistLeaveHospitalDetail ref="assistLeaveHospitalDetail" @getMethod="getMethod"></assistLeaveHospitalDetail>
      <greenHospitaltwoDetail ref="greenHospitaltwoDetail" @getMethod="getMethod"></greenHospitaltwoDetail>
      <chineseMedicineDetail ref="chineseMedicineDetail" @getMethod="getMethod"></chineseMedicineDetail> -->
    </div>
  </el-card>
</template>

<script>
// import mixinViewModule from '@/mixins/view-module'
// import AddOrUpdate from './healthtickets-add-or-update'
// import healthticketsDetails from "./healthticketsDetails";
// import advanceDetails from "./advanceDetails";
// import seriousillnessDetail from "./seriousillnessDetail";
// import controlAndConsultationDetail from "./controlAndConsultationDetail";
// import chineseMedicineDetail from "./chineseMedicineDetail";
// import OverseasClassDetail from "./OverseasClassDetail";
// import assistLeaveHospitalDetail from "./assistLeaveHospitalDetail";
// import greenHospitaltwoDetail from "./greenHospitaltwoDetail";

// /sysapp/list
//
export default {
  // mixins: [mixinViewModule],
  data() {
    return {
      listByScope: [],
      page: 1, // 当前页码
      limit: 10, // 每页数
      total: 0, // 总条数
      order: 'desc', // 排序方式
      orderField: 'id', // 排序字段
      dataList: [], // 列表数据
      dataListLoading: false,
      dataForm: {
        id: '',
        personCertificateNo: '',
        personMobile: '',
        personName: '',
        rightName: '',
        status: 1,
        supplierAppCode: '',
      },
      statusOptions: [
        { id: '', name: '全部' },
        { id: 0, name: '已取消' },
        { id: 1, name: '进行中' },
        { id: 2, name: '已完成' },
      ],
      statusArray: [
        {
          value: 0,
          text: '已取消',
        },
        {
          value: 1,
          text: '进行中',
        },
        {
          value: 2,
          text: '已完成',
        },
      ],
    };
  },
  components: {
    // healthticketsDetails,
    // advanceDetails,
    // seriousillnessDetail,
    // controlAndConsultationDetail,
    // chineseMedicineDetail,
    // OverseasClassDetail,
    // assistLeaveHospitalDetail,
    // greenHospitaltwoDetail,
  },
  created() {
    this.getServiceOrderList();
    this.getListByScope();
  },
  methods: {
    getListByScope() {
      this.$http

        .get('/sysappsupplier/listByScope')
        .then((res) => {
          if (res.data.code === 0) {
            this.listByScope = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMethod() {
      this.getServiceOrderList();
    },
    getServiceOrderList() {
      this.$http
        .get(`/healthbusiness/health-tickets/page/`, {
          params: {
            id: this.dataForm.id,
            personCertificateNo: this.dataForm.personCertificateNo,
            personMobile: this.dataForm.personMobile,
            personName: this.dataForm.personName,
            page: this.page,
            limit: this.limit,
            order: this.order,
            status: this.dataForm.status,
            rightName: this.dataForm.rightName,
            orderField: this.orderField,
            supplierAppCode: this.dataForm.supplierAppCode,
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // console.log(res);
          if (res.data) {
            this.dataList = res.data.list;
            this.total = Number(res.data.total);
          } else {
            this.dataList = [];
            this.total = 0;
          }
        })
        .catch(() => {});
    },
    GohealthticketsDetails(row) {
      // console.log('row ', row);
      switch (row.rightId) {
        // 绿通类型
        case '1': //住院绿通
        case '2': //门诊绿通
        case '3': // 专属护理（bigtree）
        case '12': // 就医陪伴
        case '17': // 检查绿通
        case '18': // 手术绿通
        case '19': // 无等候就诊
        case '29': // 住院/手术绿通
        case '30': // 重疾门诊绿通
        case '31': // 癌症门诊绿通
        case '32': // 重疾手术/住院加快绿通（含陪诊）
        case '33': //  癌症手术/住院加快绿通（含陪诊）
        case '34': //   // 就医陪伴（  含产科）
        case '52': //   // 上门护理
          this.$router.push({
            path: '/healthticketsDetails',
            query: {
              id: row.id,
            },
          });
          break;
        case '4': // 住院垫付
        case '5': // 出院垫付
          this.$router.push({
            path: '/advanceDetails',
            query: {
              id: row.id,
            },
          });
          break;

        case '28': // 预约挂号（微医）
        case '47': //复诊预约挂号
        case '48': //肿瘤基因筛查
        case '49': //质子重离子绿通
          this.$router.push({
            path: '/greenHospitaltwoDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '35': // 定期随访
        case '36': // 定期随访
        case '37': // 定期随访
        case '38': // 定期随访
          this.$router.push({
            path: '/controlAndConsultationDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '10': // 重疾二次诊断
        case '23': // MDT多学科会诊
        case '24': // 医疗报告解读
        case '39': // 视频健康会诊
        case '40': // 癌症国内二诊
        case '46': // 普通二诊
          this.$router.push({
            path: '/seriousillnessDetail',
            query: {
              id: row.id,
            },
          });
          break;

        case '11': // 协助办理出院
          this.$router.push({
            path: '/assistLeaveHospitalDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '26': // 海外就医协助
        case '41': // 海外二诊及海外就医协助
        case '42': // 海外二次诊疗
        case '43': // 癌症海外二诊
        case '44': // 重疾海外二诊
          this.$router.push({
            path: '/OverseasClassDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '20': // 中医上门理疗
          this.$router.push({
            path: '/chineseMedicineDetail',
            query: {
              id: row.id,
            },
          });
          break;
        case '58': // 初级照护
        case '59': // 中级照护
        case '60': // 高级照护
        case '61': // 专业级照护
        case '62': // （包月）初级照护
        case '63': // （包月）中级照护
        case '64': // （包月）高级照护
        case '65': // （包月）专业级照护
          this.$router.push({
            path: '/exclusiveCareDetails',
            query: {
              id: row.id,
            },
          });
          break;
        case '71': //基因检测
          this.$router.push({
            path: '/geneticTestingDetails',
            query: {
              id: row.id,
            },
          });
          break;
      }
    },
    filterStatus(value, row) {
      return row.status == value;
    },
    // 多选
    dataListSelectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 分页, 每页条数
    pageSizeChangeHandle(val) {
      this.page = 1;
      this.limit = val;
      this.getServiceOrderList();
    },
    // 分页, 当前页
    pageCurrentChangeHandle(val) {
      this.page = val;
      this.getServiceOrderList();
    },
  },
};
</script>
